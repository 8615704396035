import React from 'react'

import cx from 'classnames'
import { useEffect, useLayoutEffect } from 'react';

// import LoginForm from '../components/LoginForm'
// import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from '../authConfig';

interface AppProps {
    instance: PublicClientApplication;
}

export default function Login({ instance }: AppProps) {
    const activeAccount = instance.getActiveAccount();

    // Handle login redirect
    const handleRedirect = () => {
        instance
            .loginRedirect({
                ...loginRequest,
                prompt: 'create',
            })
            .catch((error: any) => console.log(error));
    };
    // useEffect(() => {
    //     instance
    //         .loginRedirect(loginRequest)
    //         .catch((error: any) => console.error("Login redirect failed:", error));
    // }, [instance]);

    useLayoutEffect(() => {
        // Force immediate redirect to Microsoft login
        instance
            .loginRedirect({
                ...loginRequest,
                prompt: 'create', // Forces account selection screen
                redirectStartPage: window.location.href, // Saves the page user was trying to access
            })
            .catch((error) => console.error("Login redirect failed:", error));
            
        // Fallback redirect in case the MSAL redirect fails
        const fallbackTimeout = setTimeout(() => {
            window.location.href = `/login`;
        }, 500);

        return () => clearTimeout(fallbackTimeout);
    }, [instance]);
    return <div></div>
    // return (
    //     <>
    //         <div className="relative overflow-hidden flex text-gray-700 h-[100vh]">
    //             <div
    //                 className={cx(
    //                     'font-sans relative w-full transition-all h-[100vh] flex flex-col bg-gray-100 left-0'
    //                 )}
    //             >
    //                 <header
    //                     id="header"
    //                     className="bg-white text-black flex relative items-center justify-between px-4 shadow-md shrink-0 z-10 duration-200 transition-all overflow-hidden h-[56px]"
    //                 >
    //                     <div className="flex items-center">
    //                         <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="IUCN Logo" className="h-8 w-auto mr-3" />
    //                     </div>
    //                     <h1 className="text-2xl font-bold text-center">
    //                         IUCN ChatR&R
    //                     </h1>
    //                     <div className="w-8"></div> {/* This empty div helps center the title */}
    //                 </header>
    //                 <div
    //                     className={
    //                         'flex-grow flex flex-col h-full relative overflow-auto'
    //                     }
    //                 >
    //                     <div className="flex-grow flex flex-col text-center  p-4 items-center justify-center space-y-4">
    //                         <div className="relative flex flex-col items-center bg-white rounded-md p-10">
    //                             <div className="flex flex-col items-center justify-center w-full mb-5">
    //                                 <h1 className="text-gray-700 font-bold  mb-3 text-3xl text-center">
    //                                     Welcome to the IUCN ChatR&R!
    //                                 </h1>
    //                                 <p className="text-gray-500 mb-3">
    //                                     Please log in to continue
    //                                 </p>
    //                             </div>
    //                             <div className="max-w-[450px] w-full">
    //                                 <button onClick={handleRedirect} type="submit"
    //                                     className="border-gray-300 w-full transition-all hover:bg-blue-50 text-blue-500 p-2 gap-2 rounded-md border  bg-white flex-shrink-0 flex items-center justify-center cursor-pointer "
    //                                 >
    //                                     Log in via Azure Entra
    //                                 </button>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // )
}
