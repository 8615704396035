import React, { useState, useEffect } from 'react'
import ChatInput from './components/ChatInput'
import SearchContainer from './components/SearchContainer'
import { ChatItem, ChatRoomSession, Document } from './types'
import { GoSidebarCollapse, GoSidebarExpand } from 'react-icons/go'
import cx from 'classnames'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getChatMessages, getChatRooms, getFilters } from './network/calls/chat'
import CreateChatForm from './components/CreateChatForm'
import Spinner from './components/Spinner/Spinner'
import makeRequest from './network/make-request'
import { RiChatNewLine, RiLogoutBoxRLine } from 'react-icons/ri'
import { getCurrentUserId } from './services/authService'
import { useNavigate } from 'react-router-dom'
import { getDate, isJson, scrollToBottom } from './utils/helpers'
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { useAuth } from './useAuth'

interface AppProps {
    instance: PublicClientApplication;
}


const initialMessage = {
    messages: [],
    documents: '',
    quotes: '',
    userQuestion: '',
}

interface ChatMessage {
    id: string;
    message_text: string;
    created_at: string;
    texter_role: string;
    message_data: {
        additional_information: string;
        answer: string;
        documents: any[];
        quotes_info: any[];
    };
}

export default function App({ instance }: AppProps) {
    const activeAccount = instance.getActiveAccount();
    const navigate = useNavigate();
    const { getToken } = useAuth()
    const [sidebarIsOpen, setSidebarIsOpen] = useState(false)
    const [activeChat, setActiveChat] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingStream, setIsLoadingStream] = useState(false)
    const [userId, setUserId] = useState('')
    //local storage
    const [idToken, setIdToken] = useState('')
    const [search, setSearch] = useState('')
    const [clientHeight, setClientHeight] = useState(0)
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    
    useEffect(() => {
        try {
            getToken().then((idToken) => {
                if (idToken) {
                    setIdToken(idToken);
                } else {
                    setIsAuthenticated(false);
                    console.error("Token acquisition failed:");
                    navigate('/login');
                }
            })
        } catch (error) {
            console.error("Token acquisition failed:", error);
            navigate('/login');
        }
    }, [getToken])
    useEffect(() => {
        const checkTokenValidity = async () => {
            if (!activeAccount) {
                navigate('/login');
                return;
            }

            try {
                // Attempt to silently acquire a new token
                const response = await instance.acquireTokenSilent({
                    account: activeAccount,
                    scopes: loginRequest.scopes,
                });
                if (response && response.idToken) {
                    setIsAuthenticated(true);
                    if(!userId) {
                        getCurrentUserId(idToken).then((userId) => {
                            setUserId(userId)
                        })
                    }
                }
            } catch (error) {
                console.error("Token acquisition failed:", error);
                // If token refresh fails, redirect to login
                navigate('/login');
            }
        };

        checkTokenValidity();
    }, [activeAccount, instance, navigate]);
    
    // useEffect(() => {
    //     getToken().then((token) => {
    //         if (token) {
    //             setIsAuthenticated(true)
    //         }
    //         return token;
    //     })
    // }, [])

    // Handle login redirect
    const handleRedirect = () => {
        instance
            .loginRedirect({
                ...loginRequest,
                prompt: 'create',
            })
            .catch((error) => console.log(error));
    };

    // Handle logout
    const handleLogout = () => {
        instance.logoutRedirect();
    };
    
    const handleChangeChat = (sessionId: string) => {
        const headerElement = document.querySelector('#header')
        const elementHeader = headerElement as HTMLElement
        setActiveChat(sessionId)
        elementHeader.classList.remove('!h-0')
        // localStorage.setItem('activeChatId', sessionId)
        Promise.resolve().then(scrollToBottom)
    }
    const handleNewChat = () => {
        if (isLoading || isLoadingStream) {
            alert("Please wait for the current message to finish before starting a new chat.");
            return;
        }
        const headerElement = document.querySelector('#header')
        const elementHeader = headerElement as HTMLElement
        elementHeader.classList.remove('!h-0')
        setSidebarIsOpen(false)
        setActiveChat(null)
    }

    const [customObj, setCustomObj] = useState<{
        messages: { content: string }[]
        userQuestion: string
        documents: string
        quotes: string
    }>(initialMessage)
    useEffect(() => {
    }, [customObj])
    const queryClient = useQueryClient()

    const { data: chatMessages, isLoading: chatMessagesIsLoading } = useQuery({
        queryKey: ['chatMessages', activeChat],
        queryFn: () => getChatMessages(activeChat, idToken),
        enabled: !!activeChat && isAuthenticated,
    })

    const { data: chatRooms } = useQuery({
        queryKey: ['chatRooms', userId],
        queryFn: async () => {
            return getChatRooms(userId, idToken)
        },
        enabled: !!userId,
    })

    const { data: filtersData } = useQuery({
        queryKey: ['filters'],
        queryFn: () => getFilters(idToken),
        enabled: !!userId,
    })

    const toggleSidebar = () => setSidebarIsOpen(!sidebarIsOpen)
    const chatRoomsList = (chatRooms?.data || []).filter((room) =>
        room.title.toLowerCase().includes(search.toLowerCase())
    )
    const chatMessagesList = (chatMessages?.data || []).reduce<ChatItem[]>(
        (acc, message, idx) => {
            if (idx % 2) {
                const user = (chatMessages?.data || [])[idx - 1]
                return [
                    ...acc,
                    { ...message, user_message_text: user.message_text },
                ]
            }
            return acc
        },
        []
    )

    // const { data: chatMessagesList } = useQuery({
    //     queryKey: ['chatMessages', activeChat],
    //     queryFn: async () => {
    //         const messages = await getChatMessages(activeChat, idToken);
    //         return (messages?.data || []).reduce<ChatItem[]>(
    //             (acc, message, idx) => {
    //                 if (idx % 2 && messages?.data) {
    //                     const user = messages.data[idx - 1] as ChatMessage;
    //                     return [
    //                         ...acc,
    //                         { ...message, user_message_text: user.message_text },
    //                     ]
    //                 }
    //                 return acc
    //             },
    //             []
    //         );
    //     },
    //     enabled: !!activeChat && !!idToken,
    // });


    // chatMessagesListTest contains mock message "customObj" which is dynamically filled
    // during stream
    // const chatMessagesListTest = [
    //     ...chatMessagesList,
    //     ...([
    //               {
    //                   id: '769',
    //                   message_text: customObj.messages
    //                       .map((message) => message.content)
    //                       .join(''),
    //                   created_at: new Date().toISOString(),
    //                   user_message_text: customObj.userQuestion,
    //                   texter_role: 'assistant',
    //                   message_data: {
    //                       additional_information: '',
    //                       answer: '',
    //                       documents: isJson(customObj.documents)
    //                           ? JSON.parse(customObj.documents).content || []
    //                           : [],
    //                       quotes_info: isJson(customObj.quotes)
    //                           ? JSON.parse(customObj.quotes).content || []
    //                           : [],
    //                   },
    //               },
    //           ]
    //         ),
    // ]
    // customObj is the first mock message (loading message template before stream generation) 
    const chatMessagesListTest = [
        ...(chatMessagesList ?? []),
        ...(customObj.userQuestion
            ? [
                  {
                      id: '769',
                      message_text: customObj.messages
                          .map((message) => message.content)
                          .join(''),
                      created_at: new Date().toISOString(),
                      user_message_text: customObj.userQuestion,
                      texter_role: 'assistant',
                      message_data: {
                          additional_information: '',
                          answer: '',
                          documents: isJson(customObj.documents)
                              ? JSON.parse(customObj.documents).content || []
                              : [],
                          quotes_info: isJson(customObj.quotes)
                              ? JSON.parse(customObj.quotes).content || []
                              : [],
                      },
                  },
              ]
            : []),
    ]
    
    async function listenToReadableStream(stream: ReadableStream<Uint8Array>) {
        const reader = stream.getReader() // Get the reader

        const textDecoder = new TextDecoder() // Create a TextDecoder to decode Uint8Array chunks into text

        let done = false

        while (!done) {
            const { value, done: streamDone } = await reader.read()

            if (streamDone) {
                console.log('Stream finished.')
                done = true
            } else if (value) {
                // Decode and process the chunk of data
                const text = textDecoder.decode(value, { stream: true })
                // setCustomMessage((prev) => `${prev}${text}`)
                
                setCustomObj((obj) => {
                    if (text.includes('"type": "token"')) {
                        const modifyText = text.replace(/^,/, '')
                        if (isJson('[' + modifyText + ']')) {
                            return {
                                ...obj,
                                messages: [
                                    ...obj.messages,
                                    ...JSON.parse('[' + modifyText + ']'),
                                ],
                            }
                        }
                    }
                    if (!obj.messages.length) {
                        return {
                            ...obj,
                            documents: `${obj.documents}${text}`,
                        }
                    }
                    const modifyText = text.replace(/^,/, '')
                    if (isJson('[' + modifyText + ']')) {
                        return {
                            ...obj,
                            quotes: `${obj.quotes}${modifyText}`,
                        }
                    }

                    return obj
                })
            }
        }

        reader.releaseLock() // Release the lock on the reader when done
    }

    const handleNewMessageNew = async (
        query: string,
        filters: any,
        files: File[],
        sessionId?: string
    ): Promise<void> => {
        const API_URL = process.env.REACT_APP_API_URL || ''
        const token = getToken()
        setIsLoading(true)
        setIsLoadingStream(true)
        try {
            const formData = new FormData()
            formData.append('message_text', query)
            formData.append('message_data', JSON.stringify({}))
            formData.append('message_filters', JSON.stringify(filters))
            files.forEach((file) => {
                formData.append(`files`, file)
            })
            const streaming_url = `${API_URL}/api/sessions/${sessionId || activeChat}/messages/streaming`
            setActiveChat(sessionId || activeChat)
            const headers: Record<string, string> = {
                Authorization: `Bearer ${idToken}`,
            }
            const response = await fetch(streaming_url, {
                method: 'PATCH',
                headers,
                body: formData,
            })
            setIsLoading(false)

            if (response.body) {
                setCustomObj({
                    ...initialMessage,
                    userQuestion: query,
                })
                await listenToReadableStream(response.body)

                await queryClient.refetchQueries({
                    queryKey: ['chatMessages', sessionId || activeChat],
                })
                setCustomObj(initialMessage)
            } else {
                console.error('Response does not contain a body.')
            }
        } catch (error) {
            console.error('Error fetching stream:', error)
        } finally {
            setIsLoadingStream(false)
            Promise.resolve().then(scrollToBottom)
        }
    }

    const handleCreateChatRoom = async (value: string) => {
        if (isLoading || isLoadingStream) {
            return;
        }
        setIsLoading(true)
        try {
            if (!userId) {
                throw new Error('User ID is not available')
            }
            const response = await makeRequest<ChatRoomSession>({
                url: '/api/sessions',
                method: 'POST',
                data: { user_id: userId, title: value },
                idToken: idToken,
            })
            await queryClient.refetchQueries({
                queryKey: ['chatRooms', userId],
            })
            if (response?.data) {
                setActiveChat(response.data.session_id)
                handleNewMessageNew(value, {}, [], response.data.session_id)
            }
        } catch (error) {
            console.error('Error creating chat room:', error)
        } finally {
            setIsLoading(false)
        }
    }

    
    useEffect(() => {
        const scrollElement = document.querySelector('#scroll')
        const headerElement = document.querySelector('#header')

        let lastScrollTop = 0
        if (scrollElement) {
            const handleScroll = (event: Event) => {
                const element = event.currentTarget as HTMLElement
                const elementHeader = headerElement as HTMLElement
                const currentScrollTop = element.scrollTop

                if (
                    lastScrollTop &&
                    scrollElement.scrollHeight - 50 >
                        currentScrollTop + element.offsetHeight
                ) {
                    if (currentScrollTop > lastScrollTop) {
                        elementHeader.classList.add('!h-0')
                    } else if (currentScrollTop < lastScrollTop) {
                        elementHeader.classList.remove('!h-0')
                    }
                }

                lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop
            }

            scrollElement.addEventListener(
                'scroll',
                handleScroll as EventListener
            )

            return () => {
                scrollElement.removeEventListener(
                    'scroll',
                    handleScroll as EventListener
                )
            }
        }
    }, [])
    useEffect(() => {
        if (chatMessagesListTest.length) {
            Promise.resolve().then(scrollToBottom)
        }
    }, [chatMessagesListTest.length])

    useEffect(() => {
        const scrollElement = document.querySelector('#scroll')
        if (scrollElement) {
            setClientHeight(scrollElement.clientHeight)
        }
    }, [])

    // useEffect(() => {
    //     const getUserIdHandler = async () => {
    //         const userId = await getCurrentUserId()
    //         setUserId(userId)
    //     }
    //     if (isAuthenticated) {
    //         getUserIdHandler()
    //     }
    // }, [isAuthenticated])

    useEffect(() => {
        scrollToBottom()
    }, [])

    return (
        <div className="App relative overflow-hidden flex text-gray-700 h-[100vh]">
            {/* For Authenticated Users */}
            {activeAccount && (
                <>
                    <div className="w-[300px] fixed left-0 top-0 bottom-0 border-gray-200 border-r">
                        <div className="w-[300px] h-[100vh] p-4 flex flex-col">
                            <div className="flex flex-wrap pb-4 mb-5 border-gray-200 border-b">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className="w-full flex-grow  p-2 border border-gray-300 rounded-md min-h-[40px] max-h-[120px] resize-y font-inherit"
                                />
                            </div>
                            <div className="flex gap-3 mb-5 border-gray-300 border-b pb-5">
                                <button
                                    onClick={handleNewChat}
                                    type="submit"
                                    className="border-gray-300 w-full transition-all hover:bg-blue-50 text-blue-500 p-2 gap-2 rounded-md border  bg-white flex-shrink-0 flex items-center justify-center cursor-pointer "
                                >
                                    Create new chat
                                </button>
                            </div>
                            <ul className="overflow-auto flex flex-col gap-2">
                                {chatRoomsList.map((chat, idx) => (
                                    <li
                                        key={idx}
                                        onClick={() =>
                                            handleChangeChat(chat.id)
                                        }
                                        className={cx(
                                            'min-h-[30px] transition-all shrink-0 bg-gray-50 relative rounded-md p-2.5  cursor-pointer group hover:bg-blue-50 ',
                                            {
                                                '!bg-blue-500 text-white':
                                                    chat.id === activeChat,
                                            }
                                        )}
                                    >
                                        <p className="break-all leading-4 text-xs text-gray-300 w-full mb-1 flex items-center">
                                            {getDate(chat.start_time)}
                                        </p>
                                        <p className="break-all leading-4 text-left w-full flex items-center">{chat.title}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div
                        className={cx(
                            'font-sans relative w-full transition-all h-[100vh] flex flex-col bg-gray-100 left-0',
                            { '!left-[300px]': sidebarIsOpen }
                        )}
                    >
                        <header
                            id="header"
                            className="bg-white text-black flex fixed w-full items-center justify-between px-4 shadow-md shrink-0 z-10 duration-200 transition-all overflow-hidden h-[56px]"
                        >
                            <div className="flex items-center">
                                {isAuthenticated && (
                                    <button
                                        onClick={toggleSidebar}
                                        className="mr-3 text-3xl text-black"
                                    >
                                        {sidebarIsOpen ? (
                                            <GoSidebarExpand />
                                        ) : (
                                            <GoSidebarCollapse />
                                        )}
                                    </button>
                                )}
                                <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="IUCN Logo" className="h-8 w-auto" />
                            </div>

                            <h1 className="text-2xl font-bold text-center text-black">
                                IUCN ChatR&R
                            </h1>
                            <div className="flex items-center gap-2">
                                {activeAccount && (
                                    <div className="flex flex-col items-end mr-2">
                                        <span className="text-sm font-medium text-gray-700">
                                            {activeAccount.name}
                                        </span>
                                        <span className="text-xs text-gray-500">
                                            {activeAccount.username}
                                        </span>
                                    </div>
                                )}
                                <button
                                    onClick={handleLogout}
                                    className="text-3xl text-black hover:text-gray-600 transition-colors"
                                    title="Logout"
                                >
                                    <RiLogoutBoxRLine />
                                </button>
                            </div>
                        </header>

                        <Spinner
                            id="scroll"
                            className={
                                'flex-grow flex flex-col h-full relative overflow-auto pt-[56px]'
                            }
                            isLoading={isLoading || chatMessagesIsLoading}
                        >
                            {!activeChat && (
                                <div className="flex-grow flex flex-col p-4 items-center justify-center space-y-4">
                                    <div className="max-w-[600px] w-full">
                                        <CreateChatForm
                                            handleCreateChat={handleCreateChatRoom}
                                            isLoading={isLoading || isLoadingStream}
                                        />
                                    </div>
                                </div>
                            )}
                            {activeChat && (
                                <>
                                    {chatMessagesListTest.length || chatMessagesIsLoading ? (
                                        <div className="flex-grow flex flex-col p-4  gap-4">
                                            {chatMessagesListTest.map(
                                                (item, index) => (
                                                    <SearchContainer
                                                        key={index}
                                                        chatItem={item}
                                                        chatMessagesListLength={
                                                            chatMessagesList.length
                                                        }
                                                        filters={filtersData?.data}
                                                        clientHeight={clientHeight}
                                                    />
                                                )
                                            )}
                                        </div>
                                    ) : (
                                        <div className="flex-grow h-full flex flex-col justify-center items-center">
                                            <p className="text-gray-500">
                                                No data yet
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}
                        </Spinner>

                        {activeChat && (
                            <ChatInput
                                isLoading={isLoading || isLoadingStream}
                                onSendMessage={handleNewMessageNew}
                                idToken={idToken}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    )
}