export const EMAIL_REGEXP =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/

export const PASSWORD_REGEXP =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/

export const TOKEN_NAME = 'Bearer'

export const setAccessToken = (token: string): void => {
    localStorage.setItem(TOKEN_NAME, token)
}

export const getViewerLibraryPath = (path?: string): string => {
    const docsViewerLibraryPath = process.env.REACT_APP_DOCS_VIEWER_URL
    return `${docsViewerLibraryPath}` + `/` + `${path}`
}

export const getDate = (timestamp: string) => {
    const date = new Date(timestamp)

    return date.toISOString().slice(0, 16).replace('T', ' ')
}

export function scrollToBottom() {
    const mainContainer = document.getElementById('scroll')

    if (mainContainer) {
        mainContainer.scrollTop = mainContainer.scrollHeight
    }
}

export function isJson(str: string) {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}
export function highlightTextInPdf(text: string, id: string): void {
    const pdfViewer = document.querySelector<HTMLIFrameElement>(`#${id}`)

    if (text && pdfViewer && pdfViewer.contentWindow) {
        const checkInterval = setInterval(() => {
            const pdfApplication = (pdfViewer.contentWindow as any)
                .PDFViewerApplication

            if (pdfApplication && pdfApplication.initializedPromise) {
                clearInterval(checkInterval)

                pdfApplication.initializedPromise.then(() => {
                    pdfApplication.pdfSidebar.open()
                    pdfApplication.pdfSidebar.switchView('search')

                    const searchInput =
                        pdfViewer.contentWindow!.document.getElementById(
                            'findInput'
                        ) as HTMLInputElement
                    if (searchInput) {
                        searchInput.value = text
                        searchInput.focus()

                        const event = new CustomEvent('find', {
                            detail: {
                                source: pdfApplication.findBar,
                                type: 'find',
                                query: text,
                                phraseSearch: true,
                                caseSensitive: false,
                                highlightAll: true,
                                findPrevious: false,
                            },
                        })
                        pdfApplication.findBar.dispatchEvent(event)

                        pdfApplication.pdfSidebar.close()
                    } else {
                        console.error(
                            'Search input not found in the PDF viewer'
                        )
                    }
                })
            }
        }, 100) // Check every 100 ms
    } else {
        console.error('PDF viewer not found or text is empty')
    }
}

export function formatLabel (label: string) {
    return label
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter
  };

// const label = "date_from";
// const formattedLabel = formatLabel(label);