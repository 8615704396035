import React, { useState, FormEvent, useCallback } from 'react'
import FilterWrapper from './FilterWrapper/FilterWrapper'
import { LuFilter, LuFilterX } from 'react-icons/lu'
import { FieldValues, useForm } from 'react-hook-form'
import SelectMultiple from './SelectMultiple/SelectMultiple'
import { useQuery } from '@tanstack/react-query'
import { getFilters } from '../network/calls/chat'
import TextareaAutosize from 'react-textarea-autosize'
import { IoDocumentAttachOutline } from 'react-icons/io5'
import { AiOutlineClear } from 'react-icons/ai'
import { useDropzone } from 'react-dropzone'
import { BsFiletypePdf } from 'react-icons/bs'
import ModalWrapper from './ModalWrapper/ModalWrapper'
import { IoMdClose } from 'react-icons/io'
import cx from 'classnames'
import { formatLabel } from '../utils/helpers'

interface ChatInputProps {
    onSendMessage: (message: string, filters: any, files: File[]) => void
    isLoading: boolean
    idToken: string
}

export default function ChatInput({
    onSendMessage,
    isLoading,
    idToken,
}: ChatInputProps) {
    const [input, setInput] = useState('')
    const [filterIsOpen, setFilterIsOpen] = useState(false)
    const [fileIsOpen, setFileIsOpen] = useState(false)
    const [pdfFiles, setPdfFiles] = useState<
        { url: string; name: string; file: File }[]
    >([])
    const [activePdfFile, setActivePdfFile] = useState<{
        url: string
        name: string
    } | null>(null)

    const { data: filters } = useQuery({
        queryKey: ['filters'],
        queryFn: () => getFilters(idToken),
    })

    const filtersList = Object.entries(filters?.data?.properties || {}).map(
        (prop) => ({
            name: prop[0],
            options: prop[1].anyOf[0].enum.map((v) => ({ name: v, value: v })),
        })
    )

    const { control, reset, getValues } = useForm<FieldValues>({
        mode: 'onChange',
    })

    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                const arrayBuffer = reader.result as ArrayBuffer
                const blob = new Blob([arrayBuffer], {
                    type: 'application/pdf',
                })
                const fileUrl = URL.createObjectURL(blob)
                setPdfFiles((files) => [
                    ...files,
                    { url: fileUrl, name: file.name, file: file },
                ])
            }
            reader.readAsArrayBuffer(file)
        })
    }, [])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { 'application/pdf': ['.pdf'] },
    })

    const transformFilters = (filters: any) => {
        const transformedFilters: any = {}
        for (const key in filters) {
            if (Array.isArray(filters[key])) {
                transformedFilters[key] = filters[key].map(
                    (item: any) => item.value
                )
            }
        }
        return transformedFilters
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setInput('')
        if (input.trim()) {
            const selectedFilters = getValues()
            const transformedFilters = transformFilters(selectedFilters)
            const filesToSend = pdfFiles.map((pdfFile) => pdfFile.file)
            await onSendMessage(input, transformedFilters, filesToSend)
            setPdfFiles([]) // Clear the PDF files list
        }
    }

    return (
        <>
            <form
                onSubmit={handleSubmit}
                className="relative bottom-0 p-4 bg-white border-t border-gray-200 z-100 w-full"
            >
                <FilterWrapper
                    className="w-full pb-3 gap-3"
                    isOpen={filterIsOpen}
                >
                    <div className="flex justify-between w-full gap-3">
                        <div className="flex flex-wrap gap-3 flex-grow">
                            {filtersList.map((filter) => (
                                <SelectMultiple
                                    key={filter.name}
                                    placeholder={formatLabel(filter.name)}
                                    control={control}
                                    name={filter.name}
                                    options={filter.options}
                                    topPosition
                                />
                            ))}
                        </div>
                        <button
                            type="button"
                            onClick={() => reset({})}
                            className="text-xl  border shrink-0 h-[36px] w-[36px] flex items-center justify-center border-gray-300 text-gray-500 rounded-md pt-[2px] transition-all hover:bg-blue-50"
                        >
                            <AiOutlineClear />
                        </button>
                    </div>
                </FilterWrapper>

                <FilterWrapper
                    className="w-full pb-3 gap-3"
                    isOpen={fileIsOpen}
                >
                    <div className="flex justify-between w-full gap-3">
                        <div className="flex flex-wrap gap-3 ">
                            <button
                                {...getRootProps()}
                                type="button"
                                className="text-[18px] px-2 gap-1 h-[36px] flex items-center justify-center border border-gray-300 rounded-md transition-all hover:bg-blue-50"
                            >
                                <input {...getInputProps()} />
                                <IoDocumentAttachOutline />
                                <span className="text-xs">
                                    Upload document(s) +
                                </span>
                            </button>
                            {pdfFiles.map((file, idx) => (
                                <button
                                    key={idx}
                                    onClick={() => setActivePdfFile(file)}
                                    className="text-[18px] px-2 h-[36px] flex gap-1 items-center relative justify-center border border-gray-300 rounded-md transition-all hover:bg-blue-50"
                                >
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setPdfFiles((files) =>
                                                files.filter(
                                                    (_, index) => idx !== index
                                                )
                                            )
                                        }}
                                        className="w-3 h-3 rounded-full absolute right-[-4px] top-[-4px] flex items-center justify-center text-white bg-red-500"
                                    >
                                        <IoMdClose />
                                    </button>
                                    <BsFiletypePdf />
                                    <span className="text-xs">{file.name}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </FilterWrapper>
                <div className="flex gap-3 items-start">
                    <TextareaAutosize
                        value={input}
                        disabled={isLoading}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault()
                                handleSubmit(e)
                            }
                        }}
                        placeholder={isLoading ? "Please wait until the answer is full" : "Type your message..."}
                        className="flex-grow p-2 border border-gray-300 rounded-md min-h-[42px] max-h-[120px] resize-y font-inherit placeholder:font-[300]"
                    />

                    <button
                        onClick={() => {
                            setFileIsOpen(!fileIsOpen)
                            setFilterIsOpen(false)
                        }}
                        type="button"
                        className={cx(
                            'border-gray-300 rounded-md border h-[42px] w-[42px] flex items-center justify-center cursor-pointer transition-all hover:bg-blue-50',
                            { 'bg-blue-50': fileIsOpen }
                        )}
                    >
                        <IoDocumentAttachOutline />
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            setFilterIsOpen(!filterIsOpen)
                            setFileIsOpen(false)
                        }}
                        className={cx(
                            'border-gray-300 rounded-md border h-[42px] w-[42px] flex items-center justify-center cursor-pointer transition-all hover:bg-blue-50',
                            { 'bg-blue-50': filterIsOpen }
                        )}
                    >
                        {filterIsOpen ? <LuFilterX /> : <LuFilter />}
                    </button>
                    <button
                        disabled={isLoading || !input}
                        type="submit"
                        className="px-4 h-[42px] py-2 bg-blue-500 text-white rounded-md transition-colors duration-200 hover:bg-blue-600 disabled:opacity-30"
                    >   
                        Send
                    </button>
                </div>
            </form>

            {activePdfFile && (
                <ModalWrapper>
                    <div
                        style={{
                            backgroundColor: '#38383d',
                        }}
                        className="relative w-[70vw] h-[95vh] p-8 rounded"
                    >
                        <button
                            onClick={(e) => {
                                e.stopPropagation()
                                setActivePdfFile(null)
                            }}
                            className="absolute right-2 top-2 border border-white bg-white rounded text-xl transition-all hover:bg-blue-50"
                        >
                            <IoMdClose />
                        </button>
                        <iframe
                            className="w-full h-full"
                            src={activePdfFile?.url}
                        ></iframe>
                    </div>
                </ModalWrapper>
            )}
        </>
    )
}
