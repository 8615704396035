import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export const useAuth = () => {
    const { instance } = useMsal();

    const getToken = async () => {
        const activeAccount = instance.getActiveAccount();
        if (!activeAccount) return null;

        try {
            const response = await instance.acquireTokenSilent({
                ...loginRequest,
                account: activeAccount,
            });
            return response.idToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                await instance.acquireTokenRedirect(loginRequest);
                throw error;
            } else {
                console.error(error);
                throw error;
            }
        }
    };

    return { getToken };
};
