import './index.css';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Providers from './utils/providers';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import { PublicClientApplication, EventType, AuthenticationResult } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import RedirectHandler from './RedirectHandler';
import { MsalProvider } from '@azure/msal-react';

const msalInstance = new PublicClientApplication(msalConfig);

const initializeMsalInstance = async () => {
    await msalInstance.initialize();
    const accounts = msalInstance.getAllAccounts();
    if (!msalInstance.getActiveAccount() && accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]); // Use the first account if available
    }

    msalInstance.addEventCallback((event) => {
        if (
            event.eventType === EventType.LOGIN_SUCCESS &&
            (event.payload as AuthenticationResult)?.account
        ) {
            const account = (event.payload as AuthenticationResult).account;
            msalInstance.setActiveAccount(account);
        }
    });
};

const Root = () => {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        initializeMsalInstance()
            .then(() => setInitialized(true))
            .catch((error) => {
                console.error("Error initializing MSAL:", error);
            });
    }, []);

    if (!initialized) {
        return <p></p>;
    }

    return (
        <MsalProvider instance={msalInstance}>
            <Providers>
                <Router>
                    <Routes>
                        <Route path="/" element={<App instance={msalInstance} />} />
                        <Route path="/login" element={<Login instance={msalInstance} />} />
                        <Route path="/auth/callback" element={<RedirectHandler instance={msalInstance} />} />
                    </Routes>
                </Router>
            </Providers>
        </MsalProvider>
    );
};

ReactDOM.render(<Root />, document.getElementById('root'));