import { PublicClientApplication } from '@azure/msal-browser'
import makeRequest from '../network/make-request'
import { useAuth } from '../useAuth'

interface LoginResponse {
    access_token: string
    token_type: string
}

export const login = async (
    username: string,
    password: string
): Promise<string> => {
    const response = await makeRequest<LoginResponse>({
        url: '/auth/token',
        method: 'POST',
        data: new URLSearchParams({
            username,
            password,
            grant_type: 'password',
        }),
        isAuthorized: true,
        customHeaders: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })

    if (response.errors) {
        throw new Error(response.errors)
    }

    if (response.data) {
        localStorage.setItem('token', response.data.access_token)
        window.location.href = '/'
        return response.data.access_token
    }

    throw new Error('Login failed')
}

export const logout = () => {
    localStorage.removeItem('token')
    // localStorage.removeItem('activeChatId')
}


export const getCurrentUserId = async (idToken: string): Promise<string> => {
    try {
        const response = await makeRequest<{ user_id: string }>({
            url: '/api/users/me',
            method: 'POST',
            idToken: idToken,
        })

        if (response?.data?.user_id) {
            return response.data.user_id
        } else {
            return ''
        }
    } catch (error: any) {
        console.error('Error in getCurrentUserId:', error)
        throw new Error(
            error.message || 'An unknown error occurred while fetching user ID'
        )
    }
}
